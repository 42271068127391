import { useContext, useEffect, useState } from "react";
import type React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useSpring } from "react-spring";
import invariant from "ts-invariant";

import { getPatientCareEventsQueryKey } from "api/hooks/useGetPatientCareEventsQuery";
import { getPTCareEventsQueryKey } from "api/hooks/useGetPTCareEventsQuery";
import { useMergeCareEvent } from "api/hooks/usePutPatientCareEventMutation";
import type { CareEvent } from "api/models/CareEvent";
import { useProfileContext } from "contexts/ProfileContext";
import { signNote } from "routes/patients/queries/notesApiHandler";
import type { Market, MedicalNoteSectionConfig, MedicalNoteType } from "types";
import { MedicalNoteSectionTypes } from "types";
import { AnalyticsEvents, AnalyticsService } from "utils/analytics";
import { CurrentPatientContext } from "utils/contexts";
import { getPatientFullDetailsQueryKey } from "utils/contexts/providers/useGetPatientsFullDetailsQuery";
import { reportError } from "utils/errorReporting";
import { useHeight } from "utils/hooks";
import { getMedicalNoteConfig, isChatNote, isKickOffNote } from "utils/medicalNotes";
import delay from "utils/misc/delay";

import {
  DynamicSection,
  ExclusionSection,
  MedicalNoteFormButtons,
  SystemSuggestionsSection,
  TopSection,
} from "../../../../DynamicMedicalNotes/components";
import { reduceCareEventToFields } from "../../../../DynamicMedicalNotes/helpers/careEventHelpers";
import { getAllFieldNamesFromConfig } from "../../../../DynamicMedicalNotes/helpers/dynamicMedicalNoteHelpers";
import {
  BlueWrapper,
  ButtonContainer,
  ContentWrapper,
  Form,
  StyledCol,
} from "../../../../DynamicMedicalNotes/utils/formStyles";
import { ICDCodeBaseLookup, ICDCodes } from "../../utils/ICDCodes";
import type { ShowButtons } from "../../utils/types";
import type { AutosaveStatus } from "../AutosaveIndicator";

interface Props {
  careEvent: CareEvent;
  open?: boolean;
  animate?: boolean;
  setAutosaveStatus: (status: AutosaveStatus) => void;
}

const DynamicMedicalNoteForm: React.VFC<Props> = ({ open = false, animate = false, setAutosaveStatus, careEvent }) => {
  const { id, label, medical_record_note, payer, signed_limit_reached } = careEvent;
  const [heightRef, heightMax] = useHeight<HTMLDivElement>();
  const { patient } = useContext(CurrentPatientContext);
  invariant(patient, "Patient context not set");
  const { profile } = useProfileContext();
  invariant(profile, "Profile context not set");
  const [readonly, setReadonly] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [animateHeight, setAnimateHeight] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState<ShowButtons>({ isOpen: false, type: "info" });
  const queryClient = useQueryClient();
  const { mergeCareEvent, status } = useMergeCareEvent();
  const config = getMedicalNoteConfig(profile.market as Market, label as MedicalNoteType);

  const { animDisplay, opacity, maxHeight, borderTopWidth } = useSpring({
    animDisplay: open ? 1 : 0,
    opacity: open ? 1 : 0,
    maxHeight: open ? (heightMax as number) : 0,
    borderTopWidth: open ? "1px" : "0px",
  });

  const fieldsNames = getAllFieldNamesFromConfig(config, false);

  const form = useForm({
    defaultValues: reduceCareEventToFields(fieldsNames, careEvent),
    mode: "onChange",
  });

  const { handleSubmit, watch, getValues } = form;
  const icdCodes = watch("icd_codes");

  useEffect(() => {
    setAutosaveStatus(status as AutosaveStatus);
  }, [status]);

  const onValueChange = async () => {
    await mergeCareEvent({
      careEventId: id,
      data: getValues(),
      skipInvalidation: true,
    });
  };

  // fixes medical note animation clashing with section accordions
  useEffect(() => {
    setAnimateHeight(false);
    const timeout = window.setTimeout(() => {
      setAnimateHeight(true);
    }, 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [open]);
  const onSignNote = handleSubmit(async formData => {
    setReadonly(true);
    setConfirmDisabled(true);
    const data = { ...formData, medical_record_note: "" };

    try {
      await mergeCareEvent({
        careEventId: id,
        data,
        skipInvalidation: true,
      });

      const addition = data.icd_codes?.some(code => code?.includes(ICDCodeBaseLookup.exclusion))
        ? data.structured_fields?.exclusion_reason
        : null;
      await signNote(id, addition);

      // Refetch patient profile to update treatment_started and visibility
      if (isKickOffNote(label) || (isChatNote(label) && !patient.pt_on_demand_enabled)) {
        queryClient.invalidateQueries(
          getPatientFullDetailsQueryKey({
            patientId: patient.id,
            therapistId: profile.id,
            therapistMarket: profile.market ?? "",
          })
        );
      }

      AnalyticsService.track(AnalyticsEvents.PATIENTS.SIGN_NOTE, {
        patient: patient.id,
        medicalNoteType: label,
        market: profile?.market,
        ICDcode: data.icd_code,
      });
      setShowConfirmation({ isOpen: true, type: "success" });
      await delay(3000);
    } catch (error) {
      setReadonly(false);
      setShowConfirmation({ isOpen: true, type: "danger" });
      if (error instanceof Error || typeof error === "string")
        reportError("DynamicMedicalNoteForm Error", error, {
          action: "saveAndSignNote",
          context: {
            noteType: label,
            fields: Object.keys(formData).concat(
              Object.keys(formData.structured_fields ?? {}).map(f => `structured_fields.${f}`)
            ),
          },
        });
    } finally {
      queryClient.invalidateQueries(getPatientCareEventsQueryKey({ patientId: patient.id }));
      queryClient.invalidateQueries(getPTCareEventsQueryKey({ userId: profile.id }));
      setConfirmDisabled(false);
    }
  });

  const getSection = (section: MedicalNoteSectionConfig) => {
    const isExclusionCode = icdCodes?.some(code =>
      profile.market === "SE" ? code?.includes(ICDCodes.R52_9) : code?.includes(ICDCodes.R52)
    );
    const isForcedOptional = section.forcedOptional === "isExclusionCode" && isExclusionCode;

    if (section.type === MedicalNoteSectionTypes.EXCLUSION) {
      return (
        <ExclusionSection
          readonly={readonly}
          visible={isExclusionCode}
          key="exclusion-section"
          onChange={onValueChange}
        />
      );
    }

    if (section.type === MedicalNoteSectionTypes.TOP)
      return (
        <TopSection
          readonly={readonly}
          fields={section.fields}
          key="top-section"
          legacyText={medical_record_note}
          onChange={onValueChange}
          label={label}
          payer={payer}
        />
      );

    if (section.type === MedicalNoteSectionTypes.SUGGESTIONS && section.fields?.length)
      return <SystemSuggestionsSection fields={section.fields} key="system-suggestions-section" />;

    return (
      <DynamicSection
        key={`dynamic-section-${section.name}`}
        name={section.name}
        readonly={readonly}
        fields={section.fields}
        forcedOptional={isForcedOptional}
        onChange={onValueChange}
        label={label}
        payer={payer}
        signedLimitReached={signed_limit_reached}
      />
    );
  };

  return (
    <FormProvider {...form}>
      <Form open={open} onSubmit={onSignNote}>
        <StyledCol
          data-testid="medical_note_form"
          style={
            animate
              ? {
                  opacity,
                  borderTopWidth,
                  maxHeight: animateHeight ? "" : maxHeight,
                  display: animDisplay.interpolate(displ => (displ === 0 ? "none" : "initial")),
                }
              : {}
          }
        >
          <ContentWrapper ref={heightRef}>
            {config.map(section => getSection(section))}
            <BlueWrapper active={showConfirmation?.type === "info" && showConfirmation?.isOpen} align="stretch">
              <ButtonContainer notification={showConfirmation?.isOpen}>
                <MedicalNoteFormButtons
                  cid={id}
                  confirmDisabled={confirmDisabled}
                  medicalNoteType={label}
                  showConfirmation={showConfirmation}
                  setShowConfirmation={setShowConfirmation}
                  setReadOnly={setReadonly}
                />
              </ButtonContainer>
            </BlueWrapper>
          </ContentWrapper>
        </StyledCol>
      </Form>
    </FormProvider>
  );
};

export default DynamicMedicalNoteForm;
